import {Injectable} from '@angular/core';
import {LocalStorage, LocalStorageService, SessionStorageService} from 'ngx-webstorage';
import {StorageKeys} from '@shared/lib/storage-keys';
import {User} from '../../user';

const LocalStorageKeys = {
  loginInfo: StorageKeys.LOGIN_INFO,
  bmUser: StorageKeys.USER,
  bmNewSelectedTenant: StorageKeys.NEW_SELECTED_TENANT
};

export interface LoginInfo {
  accessToken: string;
  refreshToken: string;
  userId: number;
}

@Injectable({ providedIn: 'root' })
export class AuthorizedStorage {
  @LocalStorage(LocalStorageKeys.loginInfo) loginInfo: LoginInfo;
  @LocalStorage(LocalStorageKeys.bmUser) bmUser: User;
  @LocalStorage(LocalStorageKeys.bmNewSelectedTenant) bmNewSelectedTenant: any;

  get accessToken(): string {
    return this.loginInfo?.accessToken;
  }

  get tenantEnvironmentId(): number {
    if (this.bmNewSelectedTenant?.tenant?.tenantEnvironmentId ) {
      return this.bmNewSelectedTenant?.tenant?.tenantEnvironmentId
    }
    else if (this.bmUser?.userRoles[0]?.tenantEnvironment?.id) {
      return this.bmUser.userRoles[0].tenantEnvironment.id;
    } else {
      return null;
    }
  }

  get sysAdmin(): boolean {
    return this.bmUser?.sysAdmin;
  }

  constructor(
    private localStorage: LocalStorageService,
    private sessionStorage: SessionStorageService
  ) {
  }

  setAuthValues(accessToken: string, refreshToken: string, userId: number): void {
    this.loginInfo = { accessToken, refreshToken, userId };
  }

  loggedOut(): void {
    for (let key in LocalStorageKeys) {
      this.localStorage.clear(LocalStorageKeys[key]);
    }
    this.sessionStorage.clear();
  }
}
