import {barnManagerModule} from '../index.module';
import * as angular from 'angular';
import {StorageKeys} from '@shared/lib/storage-keys';

barnManagerModule.factory('sessionStorageService', sessionStorageService);

sessionStorageService.$inject = ['$window'];
function sessionStorageService($window) {
  const mainStorage = $window.localStorage, safeStorage = $window.sessionStorage;

  const keys = {
    user: StorageKeys.USER,
    horse: 'bmHorse',
    mainPageLoaded: 'bmMainAppLoaded',
    proPageLoaded: 'bmProAppLoaded',
    mainBackUrl: 'bmMainBackUrl',
    proBackUrl: 'bmProBackUrl',
    loginInfo: StorageKeys.LOGIN_INFO,
    expiryFlag: 'bmExpiryFlag',
    testApiEndpoint: StorageKeys.TEST_API_ENDPOINT,
    newSelectedTenant: 'bmNewSelectedTenant'
  };

  return {
    setUser: setUser,
    getUser: getUser,
    setHorse: setHorse,
    getHorse: getHorse,
    setLoginInfo: setLoginInfo,
    getLoginInfo: getLoginInfo,
    setExpiryFlag: setExpiryFlag,
    getExpiryFlag: getExpiryFlag,
    clearProPageLoaded: clearProPageLoaded,
    setMainPageLoaded: setMainPageLoaded,
    getMainPageLoaded: getMainPageLoaded,
    setMainBackUrl: setMainBackUrl,
    clearProBackLink: clearProBackLink,
    getProBackUrl: getProBackUrl,
    removeAll: removeAll,
    setTestApiEndpoint: setTestApiEndpoint,
    getTestApiEndpoint: getTestApiEndpoint,
    clearTestApiEndpoint: clearTestApiEndpoint,
    keys: keys,
    setNewSelectedTenant: setNewSelectedTenant
  };

  function setUser(user) {
    mainStorage.setItem(keys.user, angular.toJson(user));
  }

  function getUser() {
    return angular.fromJson(mainStorage.getItem(keys.user));
  }

  function setHorse(horse) {
    mainStorage.setItem(keys.horse, angular.toJson(horse));
  }

  function getHorse() {
    return angular.fromJson(mainStorage.getItem(keys.horse));
  }

  function setLoginInfo(loginInfo) {
    mainStorage.setItem(keys.loginInfo, angular.toJson(loginInfo));
  }

  function getLoginInfo() {
    return angular.fromJson(mainStorage.getItem(keys.loginInfo));
  }

  function setExpiryFlag(expiryFlag) {
    safeStorage.setItem(keys.expiryFlag, angular.toJson(expiryFlag));
  }

  function getExpiryFlag() {
    return angular.fromJson(safeStorage.getItem(keys.expiryFlag));
  }

  function getMainPageLoaded() {
    return !!angular.fromJson(safeStorage.getItem(keys.proPageLoaded));
  }

  function setMainPageLoaded(loaded) {
    safeStorage.setItem(keys.mainPageLoaded, angular.toJson(loaded));
  }

  function clearProPageLoaded() {
    safeStorage.removeItem(keys.proPageLoaded);
  }

  function getProBackUrl() {
    return angular.fromJson(safeStorage.getItem(keys.proBackUrl));
  }

  function clearProBackLink() {
    safeStorage.removeItem(keys.proBackUrl);
  }

  function setMainBackUrl(mainBackUrl) {
    safeStorage.setItem(keys.mainBackUrl, angular.toJson(mainBackUrl));
  }

  function removeAll(exceptArray) {
    if (exceptArray && exceptArray.length > 0) {
      const exceptMap = new Map();
      for (const exceptArrayKey of exceptArray) {
        if (mainStorage.getItem(exceptArrayKey)) {
          exceptMap.set(exceptArrayKey, mainStorage.getItem(exceptArrayKey));
        }
      }

      mainStorage.clear();

      for (const exceptMapKey of [ ...exceptMap.keys() ]) {
        mainStorage.setItem(exceptMapKey, exceptMap.get(exceptMapKey));
      }
    } else {
      mainStorage.clear();
    }
  }

  function setTestApiEndpoint(endpoint) {
    mainStorage.setItem(keys.testApiEndpoint, endpoint);
  }

  function getTestApiEndpoint() {
    return JSON.parse(mainStorage.getItem(keys.testApiEndpoint));
  }

  function clearTestApiEndpoint() {
    mainStorage.removeItem(keys.testApiEndpoint);
  }

  function setNewSelectedTenant( env) {
    mainStorage.setItem(keys.newSelectedTenant, angular.toJson(env));
  }
}
