import {barnManagerModule} from '../index.module';
import * as angular from 'angular';

barnManagerModule.factory('sessionService', sessionService);

sessionService.$inject = [
  '$rootScope',
  '$state',
  'horseCache',
  'intercomService',
  'barnStorage',
  'subscriptionStorage',
  'userStorage',
  'responseHandler',
  'MessageFrames',
  'sessionStorageService'
];
function sessionService(
  $rootScope,
  $state,
  horseCache,
  intercomService,
  barnStorage,
  subscriptionStorage,
  userStorage,
  responseHandler,
  MessageFrames,
  sessionStorageService
) {
  let initialized = false;

  return {
    updateUser: updateUser,
    refreshUser: refreshUser,
    loadFromStorage: loadFromStorage,
    isImpersonatedEnv: isImpersonatedEnv,
    isInitialized: isInitialized,
    updateEnv: updateEnv,
    clean: clean
  };

  function updateUser(user, bootIntercom = true) {
    userStorage.setUser(user);
    initialized = true;
    if (bootIntercom) {
      intercomService.bootIntercom(user);
    }
  }

  function loadFromStorage() {
    initialized = true;
  }

  function refreshUser() {
    return userStorage.refreshUser().then(function(user) {
      updateUser(user);
    });
  }

  function clean() {
    initialized = false;
    horseCache.clean();
    userStorage.clean();
    subscriptionStorage.clean();
  }

  function isImpersonatedEnv() {
    const user = userStorage.getUser();
    return user.getBarn().id !== barnStorage.getEnvId();
  }


  function updateEnv(_env) {
    const env = angular.copy(_env);
    sessionStorageService.setNewSelectedTenant(env);
    return subscriptionStorage.loadSubscriptionByEnvId(env.id).then(() => {
      barnStorage.setEnv(env);
      $rootScope.$broadcast('environmentChange');
      if ($state.current.name) {
        $state.reload($state.current.name);
      }
    }).catch(error => responseHandler.processError(error, null, MessageFrames.TOP));
  }

  function isInitialized() {
    return angular.copy(initialized);
  }
}
